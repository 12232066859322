.rbc-event-label {
    display: none !important;
}

.rbc-event {
    background-color: rgba(0, 172, 193, 0.8);
    padding: 0;
    color: white;
}

.rbc-event-content {
    width: 100%;
    height: 100%;
}